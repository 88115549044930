.header-navbar {
  border: 1px solid #000;
  .container {
    width: 100%;
    .brand {
      cursor: pointer;
    }
    .healer-cart-img {
      margin-right: 20px;
    }
    .cart-img {
      height: 40px;
    }
    .avatar {
      height: 40px;
      width: 40px;
      margin: 0 20px;
    }
    .navbar-text {
      display: flex;
      align-items: center;
    }
  }
}

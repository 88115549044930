@import '~bootstrap/scss/bootstrap';

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  .page {
    flex: 1;
  }
}

.empty-basket {
  margin-left: 0;
  .cart-img {
    text-align: center;
    height: 250px;
    margin-top: 100px;
    .cart-empty-img {
      height: 200px;
    }
  }
}

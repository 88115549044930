.book-price-line {
  align-items: center;
  border: 1px solid #000;
  margin-top: 10px;

  &.empty {
    text-decoration: line-through;
  }
}

.total-price {
  text-align: right;
  padding-right: 0;
  padding: 5px;
}
.btn-total-price {
  padding-left: 1px;
  .form-total-price {
    padding: 0;
    padding-left: 2px;
    border: none;
  }
}

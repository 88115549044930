.specific-book {
  margin-top: 20px;
  .img-book {
    width: 100%;
  }
  .info {
    margin-bottom: 20px;
    .title {
      font-weight: bolder;
    }
    .value {
      text-align: right;
    }
  }
  .cart-info {
    border: 1px dashed #000;
    .btn-row {
      justify-content: right;
      display: flex;
    }
  }
  .form-specific-book {
    padding-right: 0;
  }
}

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  .avatar2 {
    width: 160px;
    margin-top: 5em;
    margin-bottom: 2em;
    border: 0.01em solid rgb(208, 208, 248);
    @media (max-width: 320px) {
      width: 200px;
    }
  }
  .signin-form {
    @media (max-width: 320px) {
      width: 100%;
    }
    .btn-sign-in {
      width: 100%;
    }
  }
}
